import {
    PropsWithChildren,
    ReactComponentElement,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Head, Link, router } from "@inertiajs/react"
import hero from "@/components/images/hero-1440x960.jpg"
import ApplicationLogo from "@/components/ApplicationLogo"
import Login from "@/components/Auth/Login"
import { User } from "@/types/UserClass"
import { Button } from "@mui/material"
import PrimaryButton from "@/components/Button"

export default function WelcomeLayout({
    auth,
    showSignup,
    key,
    nav,
    children,
}: PropsWithChildren<{
    auth: { user: User }
    showSignup?: () => void
    key?: string | number
    nav?: ReactComponentElement<any>
}>) {
    return (
        <>
            <Head title="Welcome" />
            <div
                className="min-h-screen overflow-hidden bg-cover"
                style={{
                    backgroundImage: `url(${hero}`,
                }}
            >
                <nav>
                    <div
                        className={`
                            mx-auto max-w-7xl px-4

                            lg:px-8

                            sm:px-6
                        `}
                    >
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex shrink-0 items-center">
                                    <Link href="/">
                                        <ApplicationLogo
                                            className={`
                                                float-left h-9 w-auto
                                                fill-current
                                            `}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div
                                className={`
                                    hidden

                                    sm:ms-6 sm:flex sm:items-center
                                `}
                            >
                                {auth.user ? (
                                    <Link
                                        href={route("dashboard")}
                                        className={`
                                            font-semibold text-gray-600

                                            dark:text-gray-400
                                            dark:hover:text-white

                                            focus:rounded-sm focus:outline
                                            focus:outline-2
                                            focus:outline-red-500

                                            hover:text-gray-900
                                        `}
                                    >
                                        Dashboard
                                    </Link>
                                ) : (
                                    <>
                                        {/*<Link*/}
                                        {/*    href={route('login')}*/}
                                        {/*    className="font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"*/}
                                        {/*>*/}
                                        {/*    Log in*/}
                                        {/*</Link>*/}

                                        {showSignup ? (
                                            <PrimaryButton
                                                className={`
                                                    mt-3 rounded-3xl
                                                    bg-bonza-primary px-5 py-2
                                                    text-bonza-dark-semi
                                                `}
                                                onClick={showSignup}
                                            >
                                                Sign up
                                            </PrimaryButton>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>

                <main
                    className={`
                        mx-auto max-w-7xl overflow-hidden px-4

                        lg:px-8

                        sm:px-6
                    `}
                >
                    {children}
                </main>
            </div>
        </>
    )
}
