import { Link, Head } from "@inertiajs/react"
import { PageProps } from "@/types"
import Login from "@/components/Auth/Login"
import WelcomeLayout from "@/layouts/WelcomeLayout"
import SignUp from "@/pages/Modals/SignUp"
import { useState } from "react"
import { Dialog } from "@headlessui/react"

export default function Welcome({
    auth,
    status,
    canRegister,
    canResetPassword,
    laravelVersion,
    phpVersion,
}: PageProps<{
    status: string
    canRegister: boolean
    canResetPassword: boolean
    laravelVersion: string
    phpVersion: string
}>) {
    const [showingSignup, setShowingSignup] = useState(false)

    const handleClose = () => setShowingSignup(false)

    return (
        <WelcomeLayout
            auth={auth}
            showSignup={() => {
                setShowingSignup(true)
            }}
        >
            <div
                className={`
                    relative mx-4 flex h-[calc(100vh-64px)] flex-row
                    overflow-auto px-2
                `}
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    className={`
                        mx-auto max-w-7xl items-center text-3xl text-white
                        flex-9

                        lg:px-8

                        sm:px-6
                    `}
                >
                    <div className="text-center">
                        <h2
                            className={`
                                mt-8 text-6xl text-gray-900

                                dark:text-white
                            `}
                        >
                            The power and joy of making music
                        </h2>

                        <h4
                            className={`
                                mt-4 text-3xl leading-relaxed text-gray-900

                                dark:text-white
                            `}
                        >
                            Together. Wherever. Whenever
                        </h4>
                    </div>
                </div>
                <div
                    className={`
                        mx-auto flex max-w-3xl flex-col items-center rounded-3xl
                        bg-white p-8 text-black

                        lg:px-16

                        md:ml-8

                        sm:px-6
                    `}
                >
                    <div className="mb-5 flex flex-row align-middle">
                        <button
                            className="w-40 py-3"
                            style={{ borderBottom: "1px solid #455dff" }}
                        >
                            Log in
                        </button>
                        <button
                            className="w-40 py-3"
                            style={{ borderBottom: "1px solid #e5e9ec" }}
                            onClick={() => setShowingSignup(true)}
                        >
                            Sign up
                        </button>
                    </div>
                    <div className="mb-6 w-80 pt-6">
                        <Login
                            status={status}
                            canResetPassword={canResetPassword}
                        />
                    </div>
                    <div
                        className="mb-4 w-80"
                        style={{ borderBottom: "1px solid #e5e9ec" }}
                    >
                        &nbsp;
                    </div>
                    <div className="w-80 pt-6 text-center">
                        Got a passcode?{" "}
                        <Link className="text-bonza-primary underline" href="#">
                            Enter here
                        </Link>
                    </div>
                </div>
            </div>
            <Dialog
                open={showingSignup}
                onClose={handleClose}
                transition
                className={`
                    fixed inset-0 flex w-screen transition duration-300 ease-out

                    data-[closed]:translate-y-full
                `}
            >
                <SignUp handleClose={handleClose} />
            </Dialog>
        </WelcomeLayout>
    )
}
