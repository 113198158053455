import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    InputHTMLAttributes,
} from "react"

export default forwardRef(function LoginTextInput(
    {
        type = "text",
        className = "",
        isFocused = false,
        ...props
    }: InputHTMLAttributes<HTMLInputElement> & { isFocused?: boolean },
    ref
) {
    const localRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
        focus: () => localRef.current?.focus(),
    }))

    useEffect(() => {
        if (isFocused) {
            localRef.current?.focus()
        }
    }, [])

    return (
        <input
            {...props}
            type={type}
            className={`
                rounded-3xl border-bonza-pale p-4

                focus:border-indigo-500 focus:ring-indigo-500

                ${className}
            `}
            ref={localRef}
        />
    )
})
